import { Component } from '@angular/core';
import { ADB2C_CODE, IDLE_TIME, IDLE_TIME_LIMIT,TOKEN } from './utils/items/storage-names';
import { version, name, copyright } from '../../package.json';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  version: string = version;
  appName: string = name;
  copyright: string = copyright;

  constructor(
    private userIdle: UserIdleService, 
    private router: Router,
  ) {
    let url = location.href;
    let codeIndex = url.indexOf('code');

    if (url.indexOf('code') !== -1) {
      let code = url.substring(codeIndex + 5, url.length).trim();
      localStorage.setItem(ADB2C_CODE, code)
    }  

    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(count => {
      localStorage.setItem(IDLE_TIME, 'true');
    });

    this.userIdle.onTimeout().subscribe(() => {
      this.logout();
    });

    window.addEventListener('storage', (event) => {
      if (event.key === 'user-inactive') {
        this.logout();
      }
    });

    const events = [
      'click',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart'
    ];

    events.forEach(event => {      
      document.addEventListener(event, () => {
        this.userIdle.resetTimer();

        if(localStorage.getItem(TOKEN)) {
          let currentDateTime = new Date();
          let newIdleTimeLimit = new Date(currentDateTime);
          newIdleTimeLimit.setHours(currentDateTime.getHours() + 8);
  
          localStorage.setItem(IDLE_TIME_LIMIT, newIdleTimeLimit.toISOString());
        }
      });      
    });
  }

  logout() {
    localStorage.clear();
    localStorage.setItem('user-inactive', 'true'); 
    this.router.navigate(['/logout']);
  }
}
