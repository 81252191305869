import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PERMANENT } from 'src/app/utils/items/address-type';
import { PHYSICAL_CLINIC } from 'src/app/utils/items/clinic-type';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { ClinicDetailComponent } from '../clinic-detail/clinic-detail.component';
import { ClinicManageScheduleComponent } from '../clinic-manage-schedule/clinic-manage-schedule.component';
import { MunicipalityService } from 'src/app/utils/services/utilities/municipality.service';
import { ClinicService } from 'src/app/utils/services/doctor/clinic.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';

@Component({
  selector: 'app-clinic-management',
  templateUrl: './clinic-management.component.html',
  styleUrls: ['./clinic-management.component.scss']
})
export class ClinicManagementComponent implements OnInit {

  @ViewChild('clinicInfo', { static: false })
  clinicInfo: ClinicDetailComponent;

  @ViewChild('manageSchedule', { static: false })
  manageSchedule: ClinicManageScheduleComponent;

  @Input()
  doctor: any;

  @Input()
  clinics: any;

  @Input()
  processing = false;

  @Input()
  serviceItems: any;

  @Input()
  onboarding: any; // TO-DO: Pass onboarding value from 

  form: FormGroup;
  selectedClinic: any = null;
  modal = false

  result: any;

  newClinic: FormGroup;
  municipalities: any;
  provinces: any;
  cities: any;
  barangays: any;
  muniProcessing = false;
  provinceProcessing = false;
  barangays$ = new BehaviorSubject<any[]>([]);
  services: any;

  constructor(
    private fb: FormBuilder,
    private service: DoctorService,
    private toastr: ToastrService,
    private clinicService: ClinicService,
    private addressService: MunicipalityService,
    private scheduleService: ScheduleService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.createNewClinicForm();
    this.listProvinces();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.clinics) {
      this.selectedClinic = this.clinics?.[0];
      this.onClinicChange();
      this.getServices();
    }
  }

  createForm() {
    this.form = this.fb.group({
      clinic: this.fb.group({
        id: [this.selectedClinic?.id],
        md_id: [this.doctor?.md_id],
        type_code: [PHYSICAL_CLINIC?.code],
        type_display: [PHYSICAL_CLINIC?.text],
        type_text: [PHYSICAL_CLINIC?.text],
        name_code: [null],
        name_display: [this.selectedClinic?.name_display, [Validators.required]],
        name_text: [this.selectedClinic?.name_text],
        site_name_code: [this.selectedClinic?.site_name_code],
        site_name_text: [this.selectedClinic?.site_name_text],
        site_name_display: [this.selectedClinic?.site_name_display],
        site_network_code: [this.selectedClinic?.site_network_code],
        site_network_text: [this.selectedClinic?.site_network_text],
        site_network_display: [this.selectedClinic?.site_network_display],
        notes: [this.selectedClinic?.notes],
        attachment: [this.selectedClinic?.attachment]
      }),
      address: this.fb.group({
        id: [this.selectedClinic?.permanent_address?.id],
        type_code: [PERMANENT?.code],
        type_text: [PERMANENT?.text],
        type_display: [PERMANENT?.text],
        line1: [this.selectedClinic?.permanent_address?.line1],
        line2: [this.selectedClinic?.permanent_address?.line2],
        barangay_code: [this.selectedClinic?.permanent_address?.barangay_code],
        barangay_text: [this.selectedClinic?.permanent_address?.barangay_text],
        city_code: [this.selectedClinic?.permanent_address?.city_code],
        city_text: [this.selectedClinic?.permanent_address?.city_text],
        state_code: [this.selectedClinic?.permanent_address?.state_code],
        state_text: [this.selectedClinic?.permanent_address?.state_text],
        country_code: [this.selectedClinic?.permanent_address?.country_code],
        country_text: [this.selectedClinic?.permanent_address?.country_text],
        zip_code: [this.selectedClinic?.permanent_address?.zip_code],
        zip_code_text: [this.selectedClinic?.permanent_address?.zip_code_text],
      }),
      contact: this.fb.group({
        id: [this.selectedClinic?.landline?.id],
        type_code: [this.selectedClinic?.landline?.type_code || 'L'],
        type_text: [this.selectedClinic?.landline?.type_text || 'Landline'],
        type_display: [this.selectedClinic?.landline?.type_display || 'Landline'],
        prefix: [this.selectedClinic?.landline?.prefix],
        value: [this.selectedClinic?.landline?.value],
        provider: [this.selectedClinic?.landline?.provider],
        remarks: [this.selectedClinic?.landline?.remarks],
      }),
      services: this.fb.array([]),
      exceptions: this.fb.array([]),
    });
  }

  createNewClinicForm() {
    this.newClinic = this.fb.group({
      type: [null, Validators.required],
      name: ['', Validators.required],
      doctor: ['', Validators.required],
      contact_number: ['', Validators.required],
      street: ['', Validators.required],
      province: [null, Validators.required],
      state_text: [null, Validators.required],
      state_code: [null, Validators.required],
      city: [null, Validators.required],
      city_text: [null, Validators.required],
      city_code: [null, Validators.required],
      barangay: [null, Validators.required],
      barangay_text: [null, Validators.required],
      barangay_code: [null, Validators.required],
      zip: [null, Validators.required]
    });
  }

  setClinic(clinic) {
    this.selectedClinic = clinic;

    setTimeout(() => {
      this.onClinicChange();
    }, 1);
  }

  setClinicDetail(clinic) {
    this.selectedClinic = clinic;

    setTimeout(() => {
      this.onClinicChange();
    }, 1);
  }

  onClinicChange() {
    this.createForm();
    setTimeout(() => {
      if (this.selectedClinic?.permanent_address?.state_code) {
        this.clinicInfo.listMunicipalities();
      }
    }, 1);
  }

  updateClinic(value) {

    this.setClinic(value);

    const index = this.clinics?.findIndex((clinic) => +clinic?.id === +value?.id);

    if (index > -1) {
      this.clinics[index] = value;
    }
  }

  delete(id) {

    const index = this.clinics?.findIndex((clinic) => +clinic?.id === +id);

    if (index > -1) {
      this.clinics.splice(index, 1);
    }

    this.setClinic(this.clinics?.[0]);
  }

  createClinic() {
    if (this.newClinic.valid) {
      this.processing = true;
      const payload = {
        md_id: this.doctor?.md_id,
        type_code: this.newClinic.value?.type,
        name_display: this.newClinic.value?.name,
        contact_type_code: "L",
        contact_number_landline: this.newClinic.value?.contact_number,
        address_type_code: "permanent",
        line1: this.newClinic.value?.street,
        barangay_code: this.newClinic.value?.barangay_code,
        barangay_text: this.newClinic.value?.barangay_text,
        city_code: this.newClinic.value?.city_code,
        city_text: this.newClinic.value?.city_text,
        state_code: this.newClinic.value?.state_code,
        state_text: this.newClinic.value?.state_text,
        zip_code: this.newClinic.value?.zip
      }
      this.clinicService.saveNewClinic(payload).subscribe(
        response => {
          this.getClinics();
          this.toastr.success("Successfully created new clinic");
          this.result = response;
          this.modal = false;
          this.onboarding = false;
          this.processing = false;
        },
        error => {
          if (error?.error && typeof error.error === 'object' && error.error.code === 'VIRTUAL_CLINIC_EXISTS') {
            this.toastr.error(error.error.error || "A virtual clinic already exists for this doctor.");
          } else {
            this.toastr.error("Something is wrong. Please contact administrator.");
          }
          this.result = error;
          this.modal = false;
          this.onboarding = false;
          this.processing = false;
        }
      );

    } else {
      this.markFormGroupTouched(this.newClinic);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  listMunicipalities() {
    this.muniProcessing = true;
    this.municipalities = null;
    const queryParams: any = {};
    queryParams.province_code = this.newClinic.value.state_code;
    queryParams.order = 'display';

    this.addressService.getMunicipality(queryParams)
      .subscribe((response: any) => {
        this.muniProcessing = false;
        this.municipalities = response;
      }, (err) => {
        this.muniProcessing = false;
      });
  }

  listProvinces() {
    this.provinceProcessing = true;
    const queryParams: any = {};
    queryParams.order = 'display';

    this.addressService.getProvinces(queryParams).subscribe((response: any) => {
      this.provinces = response;
      this.provinceProcessing = false;
    }, (err) => {
      this.provinceProcessing = false;
    });
  }

  getBarangays() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,municipal_code,display';

    this.addressService.getBarangay(queryParams).subscribe((response: any) => {
      const filteredBarangays = response?.data?.filter((brgy) => brgy?.municipal_code === this.newClinic?.value?.city_code);
      this.barangays$.next(filteredBarangays);
    });
  }

  setProvince() {
    const item = this.provinces?.data?.find((province) => province?.code === this.newClinic?.value?.province);
    this.newClinic.patchValue({
      state_code: item?.code,
      state_text: item?.display
    })
  }

  setCity() {
    const item = this.municipalities?.data?.find((muni) => muni?.code === this.newClinic?.value?.city);
    this.newClinic.patchValue({
      city_code: item?.code,
      city_text: item?.display
    })
  }

  setBarangay() {
    this.barangays$.pipe(take(1)).subscribe(barangays => {
      const selectedBarangayCode = this.newClinic.get('barangay').value;
      const selectedBarangay = barangays.find(brgy => brgy.code === selectedBarangayCode);
      if (selectedBarangay) {
        this.newClinic.patchValue({
          barangay_code: selectedBarangay.code,
          barangay_text: selectedBarangay.display
        });
      } else {
        this.newClinic.patchValue({
          barangay_code: null,
          barangay_text: null
        });
      }
    });
  }

  getClinics() {
    this.service.getClinics({
      md_id: this.doctor?.md_id,
      includes: 'exceptions,resource.services.regular_schedules.slots,resource.services.specific_schedules.slots,permanent_address,landline'
    })
      .subscribe((response: any) => {
        this.processing = false;
        this.clinics = response?.data;
      }, (err) => {
        this.processing = false;
      });
  }

  getServices() {
    if (this.selectedClinic?.resource?.id) {
      this.scheduleService.getServices({
        includes: 'service_item',
        resource_id: this.selectedClinic?.resource?.id
      })
        .subscribe((response: any) => {
          this.services = response?.data;
        }, (err) => {
          console.log(err);
        });
    }
  }
}
