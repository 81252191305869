<div class="card">
    <div class="card-header bg-primary">
        <h3 class="card-title text-lg font-weight-bold">Manage Service</h3>
    </div>
    <div class="card-body">
        <div class="row px-4">
            <div class="col">
                <div class="form-group">
                    <span class="bold-500 text-muted">Service</span>
                    <b class="ml-2 text-danger">*</b>
                    <select class="form-control mt-2" [(ngModel)]="selectedService">
                        <option [ngValue]="null" hidden>- Select service -</option>
                        <option [ngValue]="service" *ngFor="let service of serviceItems?.data">{{
                            service?.service_item_name }}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <span class="bold-500 text-muted">Professional Fees</span>
                    <b class="ml-2 text-danger">*</b>
                    <input type="text" class="form-control mt-2"  [formControl]="getServiceForm()?.get('price')">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header bg-primary">
        <!-- <h3 class="card-title text-lg font-weight-bold">{{ onboarding ? 'Manage Schedule' : 'Step 3: Manage Schedule' }}</h3> -->
        <h3 class="card-title text-lg font-weight-bold">Manage Schedule</h3>
    </div>
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <form [formGroup]="getServiceForm()" *ngIf="selectedService">
                        <fieldset [disabled]="processing">
                            <div class="form-group hidden">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input cursor-pointer" type="checkbox" id="select-all"
                                        formControlName="is_auto_booking">
                                    <label for="select-all" class="custom-control-label cursor-pointer">No schedule
                                        needed</label>
                                </div>
                            </div>

                            <div *ngIf="selectedService">
                                <ul id="custom-content-below-tab" role="tablist" class="nav nav-tabs">
                                    <li class="nav-item"><a #scheduleIndicator id="custom-content-below-home-tab"
                                            data-toggle="pill" href="#custom-content-below-home" role="tab"
                                            aria-controls="custom-content-below-home" aria-selected="true"
                                            class="nav-link active">Regular Availability</a></li>
                                    <li class="nav-item"><a id="custom-content-below-profile-tab" data-toggle="pill"
                                            href="#custom-content-below-profile" role="tab"
                                            aria-controls="custom-content-below-profile" aria-selected="false"
                                            class="nav-link">Specific Schedule</a></li>
                                    <li class="nav-item"><a #blockScheduleIndicator
                                            id="custom-content-below-messages-tab" data-toggle="pill"
                                            href="#custom-content-below-messages" role="tab"
                                            aria-controls="custom-content-below-messages" aria-selected="false"
                                            class="nav-link">Blocks / Unavailability</a></li>
                                    <li class="nav-item"><a id="custom-content-below-settings-tab" data-toggle="pill"
                                            href="#custom-content-below-settings" role="tab"
                                            aria-controls="custom-content-below-settings" aria-selected="false"
                                            class="nav-link">Delete Clinic</a></li>
                                </ul>
                                <div id="custom-content-below-tabContent" class="tab-content mt-4 mb-3">
                                    <div id="custom-content-below-home" role="tabpanel"
                                        aria-labelledby="custom-content-below-home-tab"
                                        class="tab-pane fade active show">
                                        <div *ngIf="+getServiceForm()?.value?.is_auto_booking === 1">Setting of schedule
                                            is not required for {{ selectedService?.service_item_name }} patients</div>
                                        <app-clinic-regular-availability
                                            *ngIf="+getServiceForm()?.value?.is_auto_booking === 0"
                                            [selectedService]="selectedService" [serviceForm]="getServiceForm()"
                                            [form]="form" [clinic]="clinic" [clinics]="clinics" [doctor]="doctor"
                                            [serviceItems]="serviceItems"
                                            (deleteSlot)="appendSlotDelete($event)"></app-clinic-regular-availability>
                                    </div>
                                    <div id="custom-content-below-profile" role="tabpanel"
                                        aria-labelledby="custom-content-below-profile-tab" class="tab-pane fade">
                                        <div *ngIf="+getServiceForm()?.value?.is_auto_booking === 1">Setting of schedule
                                            is not required for {{ selectedService?.service_item_name }} patients</div>
                                        <app-clinic-specific-schedule
                                            *ngIf="+getServiceForm()?.value?.is_auto_booking === 0"
                                            [selectedService]="selectedService" [serviceForm]="getServiceForm()"
                                            [form]="form" [clinic]="clinic" [clinics]="clinics" [doctor]="doctor"
                                            [serviceItems]="serviceItems" (delete)="appendScheduleDelete($event)"
                                            (deleteSlot)="appendSlotDelete($event)"></app-clinic-specific-schedule>
                                    </div>
                                    <div id="custom-content-below-messages" role="tabpanel"
                                        aria-labelledby="custom-content-below-messages-tab" class="tab-pane fade">
                                        <app-clinic-blocks-schedule [selectedService]="selectedService"
                                            [serviceForm]="getServiceForm()" [form]="form" [clinic]="clinic"
                                            [clinics]="clinics" [doctor]="doctor" [serviceItems]="serviceItems"
                                            (delete)="appendExceptionDelete($event)"></app-clinic-blocks-schedule>
                                    </div>
                                    <div id="custom-content-below-settings" role="tabpanel"
                                        aria-labelledby="custom-content-below-settings-tab" class="tab-pane fade">
                                        <app-clinic-settings [clinic]="clinic"
                                            (delete)="delete($event)"></app-clinic-settings>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <div class="text-right">
                        <button class="btn btn-primary btn-lg px-4" (click)="save()"
                            [disabled]="getServiceForm()?.invalid || form.get('exceptions')?.invalid">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>