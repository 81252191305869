<fieldset [disabled]="processing">
    <div class="row p-2 pt-4">
        <div class="col-12">
            <span class="header-text">CLINIC MANAGEMENT</span>
        </div>
        <div class="col-12 mt-2" [class.hidden]="clinics !== undefined && clinics.length > 0">
            <button type="button" class="btn btn-primary" (click)="modal = true">
                <i class="fas fa-plus pr-2"></i>Add Clinic
            </button>
        </div>
        <div class="col-12 mt-2" [class.hidden]="onboarding || clinics?.length == 0">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Clinic</span>
                        <b class="ml-2 text-danger">*</b>
                        <select id="exampleFormControlSelect1" class="form-control" [(ngModel)]="selectedClinic"
                            (change)="onClinicChange()">
                            <!-- <option selected [ngValue]="undefined">- Add New Physical Clinic -</option> -->
                            <option [ngValue]="clinic" *ngFor="let clinic of clinics">{{ clinic?.name_display }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-8 pt-4">
                    <div class="w-100 text-right">
                        <button role="button" class="btn btn-primary px-4 hidden" (click)="modal = true">
                            <i class="fas fa-pen pr-2"></i>Edit clinic
                        </button>
                        <button role="button" class="btn btn-primary px-4 ml-2" (click)="modal = true; createNewClinicForm()">
                            <i class="fas fa-plus pr-2"></i>Add clinic
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2" [class.hidden]="onboarding || clinics?.length == 0">
            <div class="row">
                <div class="col">
                    <app-clinic-manage-schedule [onboarding]="onboarding" [form]="form" [clinic]="selectedClinic"
                        [clinics]="clinics" [doctor]="doctor" [serviceItems]="serviceItems" #manageSchedule
                        (deleted)="delete($event)" (saved)="updateClinic($event)"></app-clinic-manage-schedule>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid hidden">
        <div class="row mt-4">
            <div class="col">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h3 class="card-title text-lg font-weight-bold">{{ onboarding ? 'Add Clinic' : 'Step 1: Choose a Clinic' }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <select id="exampleFormControlSelect1" class="form-control"
                                        [(ngModel)]="selectedClinic" (change)="onClinicChange()">
                                        <option selected [ngValue]="undefined">- Add New Physical Clinic -</option>
                                        <option [ngValue]="clinic" *ngFor="let clinic of clinics">{{
                                            clinic?.name_display }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="form">
            <div class="col">
                <app-clinic-detail [onboarding]="onboarding" [form]="form" [clinic]="selectedClinic" [clinics]="clinics"
                    [doctor]="doctor" (saved)="setClinic($event)" #clinicInfo></app-clinic-detail>
            </div>
        </div>
        <div class="row" *ngIf="form && selectedClinic">
            <div class="col">
                <app-clinic-manage-schedule [onboarding]="onboarding" [form]="form" [clinic]="selectedClinic"
                    [clinics]="clinics" [doctor]="doctor" [serviceItems]="serviceItems" #manageSchedule
                    (deleted)="delete($event)" (saved)="updateClinic($event)"></app-clinic-manage-schedule>
            </div>
        </div>
    </div>
</fieldset>

<p-dialog [baseZIndex]="2000" [style]="{ maxWidth: '1500px', marginTop: '30px'  }" [(visible)]="modal"
    [closable]="false" [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'"
    [draggable]="false">
    <form [formGroup]="newClinic">
        <fieldset [disabled]="processing">
            <div class="row px-4">
                <div class="col-12">
                    <h3 class="text-primary bold">Add New Clinic</h3>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Clinic Type</span>
                            <!-- <span class="bold-500 text-muted">Clinic Type (Note: You can only create a Virtual Clinic
                                once)</span> -->
                        <b class="ml-2 text-danger">*</b>
                        <select class="form-control mt-2" formControlName="type">
                            <option value="null" selected>Select Type</option>
                            <option value="physical-clinic" selected>Physical Clinic</option>
                            <!-- <option value="virtual-clinic" selected>Virtual Clinic (mWell Clinics)</option> -->
                        </select>
                        <small class="text-danger"
                            *ngIf="newClinic.get('type').invalid && newClinic.get('type').touched">
                            Clinic Type is required.
                        </small>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Clinic Name</span>
                        <b class="ml-2 text-danger">*</b>
                        <input type="text" class="form-control mt-2" formControlName="name">
                        <small class="text-danger"
                            *ngIf="newClinic.get('name').invalid && newClinic.get('name').touched">
                            Clinic Name is required.
                        </small>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Doctor</span>
                        <b class="ml-2 text-danger">*</b>
                        <input type="text" class="form-control mt-2" formControlName="doctor">
                        <small class="text-danger"
                            *ngIf="newClinic.get('doctor').invalid && newClinic.get('doctor').touched">
                            Doctor's Name is required.
                        </small>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Contact Number (Landline)</span>
                        <b class="ml-2 text-danger">*</b>
                        <input type="text" class="form-control mt-2" formControlName="contact_number" appDigitOnlyDirective>
                        <small class="text-danger"
                            *ngIf="newClinic.get('contact_number').invalid && newClinic.get('contact_number').touched">
                            Contact Number is required.
                        </small>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Street / Building / House Number</span>
                        <b class="ml-2 text-danger">*</b>
                        <input type="text" class="form-control mt-2" formControlName="street">
                        <small class="text-danger"
                            *ngIf="newClinic.get('street').invalid && newClinic.get('street').touched">
                            Street / Building / House Number is required.
                        </small>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Province</span>
                        <b class="ml-2 text-danger">*</b>
                        <select id="province" class="form-control mt-2" formControlName="province"
                            (change)="setProvince(); listMunicipalities();">
                            <option value="null">Select Province</option>
                            <option *ngFor="let province of provinces?.data" [value]="province?.code">
                                {{ province?.display }}
                            </option>
                        </select>
                        <small class="text-danger"
                            *ngIf="newClinic.get('province').invalid && newClinic.get('province').touched">
                            Province is required.
                        </small>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <span class="bold-500 text-muted">City</span>
                        <b class="ml-2 text-danger">*</b>
                        <select id="city" class="form-control mt-2" formControlName="city"
                            [attr.disabled]="!newClinic?.value?.state_code ? '' : null"
                            (change)="setCity(); this.getBarangays()">
                            <option value="null">Select City</option>
                            <option *ngFor="let city of municipalities?.data" [value]="city?.code">
                                {{ city?.display }}
                            </option>
                        </select>
                        <small class="text-danger"
                            *ngIf="newClinic.get('city').invalid && newClinic.get('city').touched">
                            City is required.
                        </small>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <span class="bold-500 text-muted">Barangay</span>
                        <b class="ml-2 text-danger">*</b>
                        <select class="form-control mt-2" formControlName="barangay"
                            [attr.disabled]="!newClinic?.value?.city_code ? '' : null" (change)="setBarangay()">
                            <option value="null" hidden>Select</option>
                            <option *ngFor="let barangay of barangays$ | async" [value]="barangay.code">
                                {{ barangay.display }}
                            </option>
                        </select>
                        <small class="text-danger"
                            *ngIf="newClinic.get('barangay').invalid && newClinic.get('barangay').touched">
                            Barangay is required.
                        </small>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <span class="bold-500 text-muted">ZIP Code</span>
                        <b class="ml-2 text-danger">*</b>
                        <input type="text" class="form-control mt-2" formControlName="zip">
                        <small class="text-danger" *ngIf="newClinic.get('zip').invalid && newClinic.get('zip').touched">
                            Zip is required.
                        </small>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
            <button role="button" class="btn btn-outline-primary btn-lg" (click)="modal = false">
                Cancel
            </button>
            <button role="button" class="btn btn-primary btn-lg mr-2 px-4" (click)="createClinic()">
                Save
            </button>
        </div>
    </p-footer>
</p-dialog>