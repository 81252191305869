<p-dropdown 
      [options]="options"
      [(ngModel)]="value"
      [optionLabel]="optionLabel"
      [filter]="true"
      [filterBy]="filterBy"
      [optionValue]="optionValue"
      [showClear]="showClear"
      [placeholder]="placeholder"
      [filterValue]="filterValue"
      (onChange)="_onChange($event)">
  </p-dropdown>