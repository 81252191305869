import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SocialAuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER, ON_CALL, TOKEN } from 'src/app/utils/items/storage-names';
import { ApiService } from 'src/app/utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() sidebarMenuOpened: boolean;
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  public searchForm: FormGroup;

  user: any;
  darkMode = false;
  currentDate: any;

  constructor(
    private _appService: AppService,
    private apiService: ApiService,
    private service: ScheduleService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.user = this._appService.getCurrentUser();
  }

  ngOnInit() {
    this.currentDate = new Date();
    this._appService.user$
      .pipe(filter(user => !!user))
      .subscribe((user: any) => this.user = user);

    this.darkMode = this.cookieService.get('settings.dark-mode') ? true : false;
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  toggleOnCall(toggle, event) {
    this.service.updateOnCallStatus(toggle.service_item_code, toggle?.resource_id, event.checked ? 1 : 0)
      .subscribe();
    localStorage.setItem(CURRENT_USER, JSON.stringify(this.user));
  }

  switchToMwellBasic() {
    let url = environment.mwell_basic;

    if (url.indexOf('platform') === -1) {
      url += '/onboard/dashboard';
    }

    location.href = url;
  }

  renderName(user) {

    if (user?.role_name === 'staff') {
      if (user?.suffix) {
        return user?.full;
      }
      else {
        return user?.full.replace('null', '');
      }
    }
    else {
      if (user?.suffix) {
        return user?.doctor?.primary_name?.full;
      }
      else {
        return user?.doctor?.primary_name?.full.replace('null', '');
      }
    }
  }

  logout() {
    this.apiService.logout()
      .subscribe((response: any) => {
        this.router.navigate(['/logout']);
      }, (err) => {
      });
  }
}
