<div class="card mt-2 bg-white">
    <div class="card-header bg-transparent pt-3">
        <div class="d-flex" *ngIf="patient">
            <div style="width: 139px;">
                <img [attr.src]="patient?.photo_url" onerror="this.src='./assets/img/user-stock.png';"
                    class="w-100 pt-2" style="border-radius: 15px; width: 139px;" />
            </div>
            <div class="pl-3 flex-grow-1">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="p-2 text-left text-uppercase pr-4"><small class="text-muted">Last Name</small>
                            <h4 class="mb-0"><b>{{ patient?.primary_name?.last }},</b></h4>
                        </div>
                        <div class="p-2 text-left pr-4"><small class="text-muted">First Name</small>
                            <h4 class="mb-0"><b>{{ patient?.primary_name?.first }}</b></h4>
                        </div>
                        <div class="p-2 text-left pr-4"><small class="text-muted">Middle Name</small>
                            <h4 class="mb-0"><b>{{ patient?.primary_name?.middle }}</b></h4>
                        </div>
                        <div class="p-2 text-left"><small class="text-muted">Suffix</small>
                            <h4 class="mb-0"><b>{{ patient?.primary_name?.suffix }}</b></h4>
                        </div>
                        <div class="p-2 text-left"><small class="text-muted">Nickname</small>
                            <h4 class="mb-0"><b>{{ patient?.nickname }}</b></h4>
                        </div>
                    </div>
                    <div class="pt-2">
                        <a type="button" class="btn btn-primary pl-5 pr-5 font-weight-bold"
                            [routerLink]="['/patient', patient?.patient_id, 'edit']" queryParamsHandling="preserve"><i class="fa fa-edit mr-2"></i> Edit Profile</a>
                    </div>
                </div>

                <div class="d-flex pt-2">
                    <div class="p-2 text-muted">Age / Sex<br>Date of Birth</div>
                    <div class="p-2 font-weight-bold flex-grow-1">{{ patient?.age_sex }}<br>{{ patient?.date_of_birth |
                        date: 'longDate' }}</div>
                    <div class="p-2 text-muted position-relative" style="width: 130px; left: -45px">Nationality<br>Religion<br>Civil Status</div>
                    <div class="p-2 font-weight-bold flex-grow-1 position-relative"style="left: -24px">{{ getAttribute('Nationality') || '-' }}<br>{{
                        getAttribute('Religion') || '-' }}<br>{{ getAttribute('Marital Status') || '-' }}</div>
                </div>
            </div>
        </div>
        <div class="d-flex w-100" *ngIf="!patient">
            <div style="width: 163px;">
                <p-skeleton height="163px" borderRadius="15px"></p-skeleton>
            </div>
            <div class="pl-3 flex-grow-1">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="p-2 text-center text-uppercase pr-4"><p-skeleton height="1.5rem"
                                width="120px"></p-skeleton><p-skeleton height="1.2rem" width="100px"
                                [style]="{ marginTop: '5px', marginLeft: '10px' }"></p-skeleton></div>
                        <div class="p-2 text-center pr-4"><p-skeleton height="1.5rem"
                                width="100px"></p-skeleton><p-skeleton height="1.2rem" width="80px"
                                [style]="{ marginTop: '5px', marginLeft: '10px' }"></p-skeleton></div>
                        <div class="p-2 text-center"><p-skeleton height="1.5rem" width="150px"></p-skeleton><p-skeleton
                                height="1.2rem" width="130px"
                                [style]="{ marginTop: '5px', marginLeft: '10px' }"></p-skeleton></div>
                    </div>
                    <div class="pt-2">
                        <p-skeleton height="57px" borderRadius="20px" width="200px"></p-skeleton>
                    </div>
                </div>
                <div class="d-flex pt-2">
                    <div class="p-2 text-muted" style="width: 130px;">Age / Sex<br>Date of Birth<br>Civil Status</div>
                    <div class="p-2 text-muted font-weight-bold flex-grow-1"><p-skeleton height="1.2rem" width="90px"
                            styleClass="mt-1"></p-skeleton><p-skeleton height="1.2rem" width="90px"
                            styleClass="mt-1"></p-skeleton><p-skeleton height="1.2rem" width="90px"
                            styleClass="mt-1"></p-skeleton></div>
                    <div class="p-2 text-muted" style="width: 130px;">Nationality<br>Religion</div>
                    <div class="p-2 text-muted font-weight-bold flex-grow-1"><p-skeleton height="1.2rem" width="90px"
                            styleClass="mt-1"></p-skeleton><p-skeleton height="1.2rem" width="90px"
                            styleClass="mt-1"></p-skeleton></div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
                <p class="bold text-color mb-0">Address Information</p>
                <div class="w-100" *ngIf="patient">
                    <div class="row">
                        <div class="text-muted col-12 col-md-2">Present Address</div>
                        <div class="font-weight-bold col-12 col-md-10">{{ patient?.present_address?.complete_address || '-' }}</div>
                    </div>
                    <div class="row">
                        <div class="text-muted col-12 col-md-2">Permanent Address</div>
                        <div class="font-weight-bold col-12 col-md-10">{{ patient?.permanent_address?.complete_address || "-" }}</div>
                    </div>
                </div>
                <div class="w-100" *ngIf="!patient">
                    <div class="row">
                        <div class="text-muted col-12 col-md-2">Present Address</div>
                        <div class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-muted col-12 col-md-2">Permanent Address</div>
                        <div class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-2 mb-md-0">
                <p class="bold text-color mb-0">Occupation Information</p>
                <div class="w-100" *ngIf="patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Present job</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.occupation?.occupation || '-' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Industry</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.occupation?.industry_type?.text || '-' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Employer</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.occupation?.employer || '-' }}</td>
                    </div>

                </div>
                <div class="w-100" *ngIf="!patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Present job</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Industry</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Employer</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
                <p class="bold text-color mb-0">Contact Information</p>
                <div class="w-100" *ngIf="patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Mobile Phone</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ getContact('M')?.contact_number_format || 'None' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Email Address</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ getContact('E')?.contact_details || 'None' }}</td>
                    </div>
                </div>
                <div class="w-100" *ngIf="!patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Mobile Phone</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Email Address</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                </div>
            </div>
            
            <div class="contact-person col-12 col-md-6 mb-2 mb-md-0">
                <p class="bold text-color mb-0">Contact in Case of Emergency</p>
                <div class="w-100" *ngIf="patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Contact Person Name</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.emergency_contact?.display || '-' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Relationship</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.emergency_contact?.relationship?.text || '-' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Purpose</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.emergency_contact?.purpose?.text || '-' }}</td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Contact Number</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.emergency_contact?.contact_number_format || '-' }}</td>
                    </div>
                    <!-- <div class="row">
                        <td class="text-muted col-12 col-md-2">PIN Number</td>
                        <td class="font-weight-bold col-12 col-md-10">{{ patient?.emergency_contact?.contact_patient_id || '-' }}</td>
                    </div>          -->
                </div>
                <div class="w-100" *ngIf="!patient">
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Contact Person Name</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Relationship</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Purpose</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                    <div class="row">
                        <td class="text-muted col-12 col-md-2">Contact Number</td>
                        <td class="font-weight-bold col-12 col-md-10"><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton>
                        </td>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="card-body pb-5">
        <div class="row">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
                <p class="bold text-color mb-0">Identity Documents</p>
                <table class="w-100" *ngIf="patient">
                    <tr *ngFor="let identifier of patient.identifiers">
                        <td class="pt-2">
                            <div class="row">
                                <!-- <div class="col-auto m-auto m-md-0">
                                    <img [attr.src]="identifier.file_url" onerror="this.src='./assets/img/user-stock.png';"
                                        class="pt-2" style="max-width:84px;border-radius: 15px;" />
                                </div> -->
                                <div class="col-12 mt-2 mt-md-0">
                                    <div class="row">
                                        <div class="text-muted col-12 col-md-2">Valid ID Type: </div>
                                        <div class="font-weight-bold col-12 col-md-10">{{ identifier.type?.text }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="text-muted col-12 col-md-2">ID Number: </div>
                                        <div class="font-weight-bold d-block d-md-inline col-12 col-md-10">
                                            {{ identifier.id_number }}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="identifier.registration_date != null">
                                        <div class="text-muted col-12 col-md-2">Registration
                                            Date:
                                        </div>
                                        <div class="font-weight-bold d-block d-md-inline col-12 col-md-10">
                                            {{ identifier.registration_date_display }}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="identifier.valid_until != null">
                                        <div class="text-muted col-12 col-md-2">Valid Until:</div>
                                        <div class="font-weight-bold d-block d-md-inline col-12 col-md-10">{{
                                            identifier.validity_display }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <table class="w-100" *ngIf="!patient">
                    <tr>
                        <td class="pt-2">
                            <div class="row">
                                <div class="col-auto">
                                    <p-skeleton width="84px" height="84px" borderRadius="15px"></p-skeleton>
                                </div>
                                <div class="col">
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pt-2">
                            <div class="row">
                                <div class="col-auto">
                                    <p-skeleton width="84px" height="84px" borderRadius="15px"></p-skeleton>
                                </div>
                                <div class="col">
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                    <div><p-skeleton height="1.2rem" width="300px" styleClass="mt-1"></p-skeleton></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>