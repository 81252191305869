import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { MunicipalityService } from 'src/app/utils/services/utilities/municipality.service';

@Component({
  selector: 'app-clinic-detail',
  templateUrl: './clinic-detail.component.html',
  styleUrls: ['./clinic-detail.component.scss']
})
export class ClinicDetailComponent implements OnInit {

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  doctor: any;

  @Input()
  form: FormGroup;

  @Input()
  clinic: any;

  @Input()
  clinics: any;

  @Input()
  onboarding = false;

  municipalities: any;
  provinces: any;
  cities: any;
  processing = false;
  muniProcessing = false;
  provinceProcessing = false;

  constructor(
    private addressService: MunicipalityService,
    private service: DoctorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listProvinces();
  }

  getControl(name) {
    return this.form.get(name);
  }

  listMunicipalities() {
    this.muniProcessing = true;
    this.municipalities = null;
    const queryParams: any = {};
    queryParams.province_code = this.form.value.address.state_code;
    queryParams.order = 'display';

    this.addressService.getMunicipality(queryParams)
    .subscribe((response: any) => {
      this.muniProcessing = false;
      this.municipalities = response;
    }, (err) => {
      this.muniProcessing = false;
    });
  }

  listProvinces() {
    this.provinceProcessing = true;
    const queryParams: any = {};
    queryParams.order = 'display';

    this.addressService.getProvinces(queryParams).subscribe((response: any) => {
      this.provinces = response;
      this.provinceProcessing = false;
    }, (err) => {
      this.provinceProcessing = false;
    });
  }

  setProvince() {
    const item = this.provinces?.data?.find((province) => province?.code === this.form?.value?.address?.state_code);
    this.form.get('address').patchValue({
      state_text: item?.display
    })
  }

  setCity() {
    const item = this.municipalities?.data?.find((muni) => muni?.code === this.form?.value?.address?.city_code);
    this.form.get('address').patchValue({
      city_text: item?.display
    })
  }

  save() {
    this.processing = true;
    this.service.saveClinic(this.form.value, {
      includes: 'exceptions,resource.services.regular_schedules.slots,resource.services.specific_schedules.slots,permanent_address,landline'
    })
    .subscribe((response: any) => {
      this.processing = false;

      const index = this.clinics?.findIndex((clinic) => +clinic?.id === +response?.id);

      if(index > -1) {
        this.clinics[index] = response;
      } else {
        this.clinics.push(response);
      }

      this.clinic = response;
      this.saved.emit(response);
      this.toastr.success("Successfully saved the clinic information");
    }, (err) => {
      this.processing = false;
    });
  }

}
