<div>
  <p-dialog [baseZIndex]="2200" [style]="{ minWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
  [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <!-- <ng-template pTemplate="header">
      <h3 class="text-primary bold-700 text-uppercase">Attendance</h3>
    </ng-template> -->
    
    <div>
        <div class="notification-status-icon">
          <img *ngIf="status == 'success'" src="./assets/img/notification-status-success.png" />
        </div>
        <div class="notification-status-title">{{ this.title ? this.title : '' }}</div>
        <div class="notification-status-message">{{ this.message ? this.message : '' }}</div>
    </div>

    <ng-template pTemplate="footer">
      <button role="button" class="btn btn-primary btn-lg my-0 mx-auto mt-2 d-block notification-status-button" (click)="onClose()">
        OK
      </button>
    </ng-template>
  </p-dialog>
</div>