import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>+]/.test(password);

    const passwordCategories = [
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
    ];

    const categoriesCount = passwordCategories.filter(Boolean).length;

    return categoriesCount < 3
      ? { passwordRequirements: true }
      : null;
    };
}