<div>
  <div class="card light-shadow position-relative">
    <div class="grid">
      <div class="row">
        <div class="col-12 text-center">
          <div class="my-3">
            <img class="logo pr-2" src="assets/img/mwell-MD-icon.png" width="90px">
            <img class="logo" src="assets/img/logo.png" width="200px">
          </div>
          <div class="welcome-text">Sign up</div>
          <span class="sub-header">We have sent a verification code to your Email</span>
          <div class="doctors-email">{{ user.email }}</div>
          <div class="sign-up-form">
            <form *ngIf="form" [formGroup]="form">
              <fieldset [disabled]="processing">
                <div><span style="font-size: 12px;">Please enter the verification code sent to your Email Address</span>
                </div>
                <div class="mt-2">
                  <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"
                    class="input-box"></ng-otp-input>
                  <div class="col-12">
                    <h6 class="text-danger bold pt-3" *ngIf="timeExpired?.isExpired"> Verification code already expired.
                    </h6>
                    <h6 class="text-success pt-3" *ngIf="!timeExpired?.isExpired">
                      Verification code will expire in
                      <span class="text-danger bold">{{ (4-timeExpired?.minutes) }}:{{ (59-timeExpired?.seconds)
                        }}</span> minutes
                    </h6>
                  </div>
                </div>
                <div class="row d-flex pt-3">
                  <div class="col-6 pt-1 pr-0 text-right">
                    <span>Didn't get the code?</span>
                  </div>
                  <div class="col-6 pl-0">
                    <a href="javascript:;" class="btn btn-outline-primary bold" [disabled]="processing"
                      (click)="resend()">Resend Code</a>
                  </div>
                </div>
                <div class="row d-flex pt-48">
                  <div class="col-6">
                    <a href="javascript:;" class="btn btn-outline-primary btn-block" (click)="back()">Back</a>
                  </div>
                  <div class="col-6">
                    <button type="submit" [disabled]="otpLength < 6" (click)="submit()"
                      class="btn btn-primary btn-block">
                      Submit
                      <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>