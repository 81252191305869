<div>
  <div class="card light-shadow position-relative">
    <div class="grid">
      <div class="row mb-4">
        <div class="col-12 text-center">
          <div class="my-3">
            <img class="logo pr-2" src="assets/img/mwell-MD-icon.png" width="90px">
            <img class="logo" src="assets/img/logo.png" width="200px">
          </div>
        </div>
      </div>
      <div class="container pt-4 text-center">
        <span><i class="fas fa-check-circle fa-8x text-success"></i></span>
        <div class="verification-text">Verification In Progress</div>
        <div class="row">
          <span class="col-12 sub-header">You have submitted your application successfully.</span>
          <span class="col-12 sub-header">We will notify you via email once validation is complete.</span>
          <span class="col-12 sub-header">Thank you for your patience!</span>
        </div>
        <a href="javascript:;" class="btn btn-primary bold btn-okay" (click)="logout()">OK</a>
      </div>
    </div>
  </div>
</div>