import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  ElementRef,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getStorage } from 'src/app/utils/helper/storage';
import {
  DRAFT_DOCUMENT,
  FINAL_DOCUMENT,
} from 'src/app/utils/items/clinical-documentation-status';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { VisitService } from 'src/app/utils/services/mpi/visit.service';
import { DischargeModalComponent } from 'src/app/components/inpatient/discharge-modal/discharge-modal.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-clinical-document-list-template',
  templateUrl: './clinical-document-list-template.component.html',
  styleUrls: ['./clinical-document-list-template.component.scss'],
})
export class ClinicalDocumentListTemplateComponent implements OnInit {
  @Output()
  reopen: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  generatePDF: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  title = 'CLINICAL DOCUMENTS';

  @Input()
  source = 'clinical-documentation';

  @Input()
  visit: any;

  @ViewChildren('panels') private panels: QueryList<ElementRef>;

  @ViewChild('dischargePatientModal')
  dischargeModal: DischargeModalComponent

  processing = true;
  expanded = false;
  clinicalDocuments: any;
  draftCode = DRAFT_DOCUMENT;
  page: any = 1;
  perPage: any = 25;
  perPages = [5, 10, 25, 50, 100];
  queryParams: any = {};
  user: any;
  doctor: any;
  buttonExpand: string = 'Expand';
  worklistType: string;

  constructor(
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private service: FormService,
    private renderer: Renderer2,
    private visitService: VisitService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.worklistType = params['worklistType'];
    });
    this.user = getStorage(CURRENT_USER, true);
  }

  getQueryParams(page) {
    this.queryParams.page = this.page = page;
    this.queryParams.perPage = this.perPage;
    this.user = getStorage(CURRENT_USER, true);
    return this.queryParams;
  }

  list(page = 1) {
    this.processing = true;
    const queryParams = this.getQueryParams(page);

    this.service.getDocuments(queryParams).subscribe(
      (response: any) => {
        this.clinicalDocuments = response;
        this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  sendDocument(document) {
    document.sending = true;

    this.service
      .sendDocument(document?.id, {
        doctor: this.doctor,
        pdfs: this.getSelectedDocuments(document)?.map((item) => {
          return {
            file: item?.pdf,
            name: item?.name,
          };
        }),
      })
      .subscribe(
        (response: any) => {
          this.toastr.success('File(s) has been queued for sending!', '', {
            timeOut: 3000,
          });
          document.sending = false;
          document?.pdfs.forEach((pdf) => {
            pdf.selected = false;
          });
        },
        (err) => {
          document.sending = false;
        }
      );
  }

  getSelectedDocuments(document) {
    return document?.pdfs?.filter((item) => item?.selected);
  }

  updatePerPage() {
    this.list(this.page);
  }

  sanitizeHTML(html: string = '') {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getDates(date) {
    return date;
  }

  onReopenDocument(document) {
    // if (+document?.document_status_code === +FINAL_DOCUMENT) {
    //   this.reopen.emit(document);
    // } else {
    this.router.navigate(
      [
        '/patient',
        document?.patient_id,
        'visit',
        document?.visit_id,
        'document',
        document?.template_code,
        document?.id,
      ],
      {
        queryParams: {
          source: this.source,
        },
        queryParamsHandling: 'preserve'
      }
    );
    // }
  }

  onGeneratePDF(data) {
    this.generatePDF.emit(data);
  }

  canEdit(document) {
    if (
      document?.created_by === this.user?.id ||
      document?.approvers?.find((approver) => approver?.id === this.user?.id) ||
      this.user?.role_name != 'staff'
    ) {
      return true;
    }
    return false;
  }

  toggleCollapse(data) { }

  hasDisable(pdfs) {
    return pdfs?.find((item) => item.generating);
  }

  toggleCollapseAll() {
    //check if there are existing open panels
    // this.panels.forEach((item, itemIndex: number) => {
    //   let active = item.nativeElement.classList.contains('show');
    //   if (active || this.expanded) {
    //     this.renderer.removeClass(item.nativeElement, 'show');
    //     this.expanded = false;
    //     this.buttonExpand = 'Expand';
    //   } else {
    //     this.expanded = true;
    //     this.buttonExpand = 'Collapse';
    //   }
    // });

    this.panels.forEach((item, itemIndex: number) => {
        if (this.buttonExpand == 'Collapse') {
          this.renderer.removeClass(item.nativeElement, 'show');
          this.expanded = false;
        } else {
          this.renderer.addClass(item.nativeElement, 'show');
          this.expanded = true;
        }
    });

    if (this.buttonExpand == 'Collapse') {
      this.buttonExpand = 'Expand';
    } else {
      this.buttonExpand = 'Collapse';
    }
  }

  setDoctor(form) {
    this.doctor = form.value;
  }

  setDocument(document) {
    this.sendDocument(document);
  }

  process(visit) {
    if (visit?.patient_class_code === 'OP') {
      this.visitService.updateDischargeDateTime(this.visit?.visit_id)
        .subscribe(
          (res: any) => {
            if (res != null) {
              visit.discharge_datetime = res.discharge_datetime;
              this.toastr.success(`Visit ${visit.admission_date_string} ended successfully!`, '', {
                timeOut: 3000,
              });
            }
          }
        );
    } else {
      this.dischargeModal.onOpen(visit)
    }
  }
}
