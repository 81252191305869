
export function getMasterByName(name: string) {
    return localStorage.getItem('value_master') ? JSON.parse(localStorage.getItem('value_master')).find((item) => {
        return item?.text === name;
    }) : null;
}

export function isAcceptableImagePdf(type) {
    return type.indexOf('pdf') >= 0 || type.indexOf('image') >= 0 ? true : false
}

export function isAcceptableImage(type) {
    return type.indexOf('image') >= 0 ? true : false
}

export function isAcceptableImageAndDocument(type) {
    return type.indexOf('docx') >= 0 ||type.indexOf('doc') >= 0 || type.indexOf('pdf') >= 0 || type.indexOf('image') >= 0 ? true : false
}