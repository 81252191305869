import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DoctorService } from '../services/doctor/doctor.service';
import { AppService } from '../services/app.service';

@Injectable({
    providedIn: 'root'
})
export class DoctorResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _doctorService: DoctorService,
        private _appService: AppService) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial doctor every time they visit dashboard.
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let user = this._appService.getCurrentUser();
        this._appService.user = user;

        this._doctorService.getDoctorByUser(user?.id, { includes: 'medical_documents,prc_license.file,prc_license.multiple_files,s2_license.file,ptr_license.file,specialties.specialty,subspecialties.specialty,hospital_affiliations.hospital_master,hmo.hmo_master,medical_associations,present_address,permanent_address,contact_email,contact_mobile,birth_place' })
            .pipe(catchError(e => null))
            .subscribe((res: any) => {
                console.log('Doctor details fetched every page refresh: ', res);

                if (!res) {
                    this._appService.doctor = user.doctor;
                } else {
                    this._appService.doctor = res;
                }
            });

        return of(null);
    }
}
