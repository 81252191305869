<div>
  <p-dialog
    [baseZIndex]="2000"
    [style]="{ maxWidth: '600px', marginTop: '30px'  }"
    [(visible)]="modal"
    [closable]="true"
    [closeOnEscape]="true"
    [dismissableMask]="false"
    [modal]="true"
    [position]="'center'"
    [draggable]="false"
  >
    <ng-template pTemplate="header">
      <div class="w-100">
        <h4 class="bold text-primary">Add Bank Account</h4>
      </div>
    </ng-template>
    <section>
      <div class="row px-2">
        <div class="col-12 pb-4">
          <span for="bank" class="bold">Bank</span>
          <select class="form-control w-100">
              <option value="bpi">Bank of the Philippine Islands (BPI)</option>
          </select>
        </div>
        <div class="col">
          <span for="first" class="bold">First Name</span>
          <input type="text" class="form-control" >
        </div>
        <div class="col">
          <span for="last" class="bold">Last Name</span>
          <input type="text" class="form-control" >
        </div>
        <div class="col-12 py-4">
          <span for="card_number" class="bold">Card Number</span>
          <input type="text" class="form-control" >
        </div>
        <div class="col">
          <span for="Expiration" class="bold">Expiration</span>
          <input type="date" class="form-control" >
        </div>
        <div class="col">
          <span for="cvv" class="bold">CVV</span>
          <input type="text" class="form-control" >
        </div>
      </div>
    </section>

    <p-footer>
      <div class="w-100 text-center">
        <button role="button" class="btn btn-primary btn-lg px-4 mr-4" >
            Save
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg px-4" (click)="modal = false">
            Cancel
        </button>
      </div>
    </p-footer>
  </p-dialog>
</div>