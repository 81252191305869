<div>
  <p-dialog
    [baseZIndex]="2000"
    [style]="{ maxWidth: '1400px', marginTop: '30px'  }"
    [(visible)]="modal"
    [closable]="true"
    [closeOnEscape]="true"
    [dismissableMask]="false"
    [modal]="true"
    [position]="'center'"
    [draggable]="false"
  >
    <ng-template pTemplate="header">
      <div class="w-100 text-center">
        <h4 class="bold">Choose a plan that works for you</h4>
      </div>
    </ng-template>
    <section>
      <div class="border-style mt-4">
        <table class="table table-bordered mb-0">
          <thead>
              <tr>
                  <th class="p-4 border-bottom-0 position-relative w-25">
                    <div class="position-absolute top-20">
                      <h3 class="bold ">Subscription Plans</h3>
                      <div class="text-muted bold-400">
                        You are currently on the <span class="text-primary">MD Free Plan.</span> Upgrade to unlock unlimited integrations and advance features.
                      </div>
                    </div>
                  </th>
                  <th class="pl-0 border-bottom-0 w-25 text-center position-relative">
                    <h3 class="bold position-absolute w-100 top-20">MD Free</h3>
                  </th>
                  <th class="pl-0 border-bottom-0 position-relative w-23 border-top border-left border-right">
                    <div class="position-absolute top-20 text-center w-100">
                      <h3 class="bold">Premium</h3>
                      <div class="">
                        <h1 class="bold">P 1900.00</h1>
                        <span class="bold-500">Monthly</span>
                      </div>
                    </div>
                    <div class="position-absolute w-100 px-4" style="top: -22px;">
                      <button role="button" class="btn btn-primary btn-block btn-lg py-1">
                        <i class="fas fa-heartbeat mr-2"></i>
                        Most Recommended
                      </button>
                    </div>
                  </th>
                  <th class="p-4 border-bottom-0 w-25">
                    <div class="w-100 text-center">
                      <h3 class="bold">Enterprise</h3>
                      <div class="text-muted bold-400">
                        Designed for large practices, hospitals, or clinics needing full-scale management and integration.
                      </div>
                      <div class="pt-2">
                        Email us on <span class="text-primary">support@mwell.com.ph</span>
                      </div>
                    </div>
                  </th>
              </tr>
              <tr>
                <th class="px-4">
                  <div>
                    <h3 class="bold">Pricing and Features</h3>
                  </div>
                </th>
                <th class="px-4">
                  <div>
                    <button role="button" class="btn btn-outline-primary btn-block btn-lg">
                      Your Current Plan
                    </button>
                  </div>
                </th>
                <th class="px-4 border-left border-right">
                  <div>
                    <button role="button" class="btn btn-primary btn-block btn-lg" (click)="subscriptionPayment?.onOpen()">
                      Upgrade Now
                    </button>
                  </div>
                </th>
                <th class="px-4">
                  <div>
                    <button role="button" class="btn btn-primary btn-block btn-lg">
                      Get in touch
                    </button>
                  </div>
                </th>
              </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>Clinics: Manage unlimited clinics.</span>
              </td>
              <td class="text-center">
                <span>Only one Clinic</span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>Custom</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Devices: Supports unlimited devices</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Patients: Manage unlimited patients</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  200 Patients
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Adding Patients: Patients can be booked via mWell or manually.</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Free Tracking: Manual fee tracking and automatic for teleconsultations.</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Design: Customizable headers for all patient forms. (Dx, Rx, Medcert & Clinical Abstract)</span>
              </td>
              <td class="text-center">
                <span>
                  Standard
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Sending Documentation: Documentation can be emailed to all patients. (physical & virtual)</span>
              </td>
              <td class="text-center">
                <span>
                  Mobile app & email enabled for Teleconsultation only
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Notification: System notifications are sent via e-mail.</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Migration: Assistance with migration to MD.</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-times text-danger fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Profile: Profile feature is enhanced</span>
              </td>
              <td class="text-center">
                <span>
                  Basic
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Forms: Access to all forms</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-times text-danger fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Templates for Forms</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-times text-danger fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Sub-user</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-times text-danger fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Manage News and Announcements</span>
              </td>
              <td class="text-center pb-0">
                <span>
                  <i class="fas fa-times text-danger fa-2x"></i>
                </span>
              </td>
              <td class="text-center pb-0 border-left border-right border-bottom">
                <span>
                  <i class="fas fa-check text-success fa-2x"></i>
                </span>
              </td>
              <td class="text-center">
                <span>
                  Custom
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </p-dialog>
</div>

<app-subscription-payment-modal #subscriptionPayment></app-subscription-payment-modal>
