import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { info } from 'console';
import { ToastrService } from 'ngx-toastr';
import { CCON } from 'src/app/utils/items/service-type';
import { week } from 'src/app/utils/items/week';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';

@Component({
  selector: 'app-clinic-manage-schedule',
  templateUrl: './clinic-manage-schedule.component.html',
  styleUrls: ['./clinic-manage-schedule.component.scss']
})
export class ClinicManageScheduleComponent implements OnInit,OnChanges {

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('scheduleIndicator', {static: false})
  scheduleIndicator: ElementRef;

  @ViewChild('blockScheduleIndicator', {static: false})
  blockScheduleIndicator: ElementRef;

  @Input()
  doctor: any;

  @Input()
  form: FormGroup;

  @Input()
  clinic: any;

  @Input()
  clinics: any;

  @Input()
  serviceItems: any;

  @Input()
  onboarding = false;

  days = week;
  processing = false;
  selectedService: any;
  scheduleToDelete = [];
  slotToDelete = [];
  exceptionToDelete = [];
  
  constructor(
    private service: ScheduleService,
    private toastr: ToastrService,
    private doctorService: DoctorService,
    private fb: FormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.form) {
      this.selectedService = this.selectedService || this.serviceItems?.data?.[0];
      this.setServices();
    }
  }

  ngOnInit(): void {
  }

  get services() {
    return this.form.get('services') as FormArray;
  }

  setServices() {
    while (this.services.length !== 0) {
      this.services.removeAt(0);
    }
  
    this.serviceItems?.data?.forEach((serviceItem) => {
      const clinicService = this.clinic?.resource?.services?.find((service) => service?.service_item_code === serviceItem?.service_item_code);
      const schedules = [];
  
      this.days.forEach((day) => {
        const serviceSchedule = clinicService?.regular_schedules?.find((sched) => sched?.schedule_day === day?.code && sched?.schedule_type === 'generic');
        const scheduleForm = this.createSchedule(serviceSchedule, day?.code);
        schedules.push(scheduleForm);
      });
  
      this.services.push(
        this.fb.group({
          id: [clinicService?.id],
          service_item_code: [serviceItem?.service_item_code],
          price: [clinicService?.price || 0],
          auto_accept: [clinicService?.auto_accept || 1],
          is_on_call: [clinicService?.is_on_call || 1],
          is_on_call_toggleable: [clinicService?.is_on_call_toggleable || 1],
          is_auto_booking: [serviceItem?.auto_accept],
          payment_required: [clinicService?.payment_required || 1],
          regular_schedules: this.fb.array(schedules),
          specific_schedules: this.fb.array([]),
        })
      );
    });
  }



  createSchedule(schedule = null, day) {
    return this.fb.group({
      id: [schedule?.id],
      schedule_type: ['generic'],
      service_type_code: [CCON, Validators.required],
      schedule_day: [day, Validators.required],
      schedule_start_time: [schedule?.schedule_start_time],
      schedule_end_time: [schedule?.schedule_end_time],
      remarks: [schedule?.remarks],
      interval: [schedule?.interval || 1],
      slots: this.fb.array([], [Validators.minLength(1)]),
      generator: this.fb.group({
        interval: [1],
        start_time: [1],
        end_time: [1],
      })
    })
  }

  getServiceForm(): FormGroup {
    const index = this.services?.value?.findIndex((service) => service?.service_item_code === this.selectedService?.service_item_code);
    return this.services.at(index) as FormGroup;
  }

  toggleAutoBooking() {
    if(+this.getServiceForm()?.value?.is_auto_booking === 1) {
      setTimeout(() => {
        this.blockScheduleIndicator?.nativeElement?.click();
      }, 5);
    } else {
      setTimeout(() => {
        this.scheduleIndicator?.nativeElement?.click();
      }, 5);
    }
  }

  appendScheduleDelete(id) {
    this.scheduleToDelete.push(id);
  }

  appendSlotDelete(id) {
    this.slotToDelete.push(id);
  }

  appendExceptionDelete(id) {
    this.exceptionToDelete.push(id);
  }

  save() {
    this.toastr.info("Processing request, please wait.");
    this.processing = true;
    this.service.saveClinicSchedule(this.form.value?.clinic?.id, {
      ...this.form.value,
      ...{schedule_to_delete: this.scheduleToDelete},
      ...{slot_to_delete: this.slotToDelete},
      ...{exception_to_delete: this.exceptionToDelete}
    })
    .subscribe((response: any) => {
      this.slotToDelete = [];
      this.scheduleToDelete = [];
      this.exceptionToDelete = [];
      this.processing = false;


      this.saved.emit(this.clinic = response);
      this.toastr.success("Successfully updated the clinic schedule");
    }, (err) => {
      this.processing = false;
    });
  }

  delete(id) {
    this.toastr.info("Processing request, please wait.");
    this.processing = true;
    this.service.deleteClinic(id)
    .subscribe((response: any) => {
      this.processing = false;
      this.deleted.emit(id);
      this.toastr.success("Successfully deleted the clinic schedule");
    }, (err) => {
      this.processing = false;
    });
  }

}
