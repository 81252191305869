import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscriptionAddBankComponent } from '../subscription-add-bank/subscription-add-bank.component';
import { SubscriptionAddEwalletComponent } from '../subscription-add-ewallet/subscription-add-ewallet.component';

@Component({
  selector: 'app-subscription-payment-modal',
  templateUrl: './subscription-payment-modal.component.html',
  styleUrls: ['./subscription-payment-modal.component.scss']
})
export class SubscriptionPaymentModalComponent implements OnInit {
  @ViewChild('subscriptionAddBank', { static: true }) subscriptionAddBank: SubscriptionAddBankComponent;
  @ViewChild('subscriptionAddEwallet', { static: true }) subscriptionAddEwallet: SubscriptionAddEwalletComponent;

  modal = false;

  constructor() {}

  ngOnInit(): void {
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}
