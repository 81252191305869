import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownSearch implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder = '';
  @Input() options: Array<any> | undefined;
  @Input() filterBy: Array<any> | undefined;
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() value: any | undefined;
  @Input() showClear: boolean = false;

  filterValue: string = '';

  constructor() { }

  ngOnInit(): void { }

  _onSelect(item) {
    this.onSelect.emit(item);
  }

  _onChange(event: any) {
    let data = this.options.find(item => item[this.optionValue] === event.value);

    data = {
      ...data,
      value: event.value
    };
    
    this.onChange.emit(data);
  }
}
