<div>
  <p-dialog
    [baseZIndex]="2000"
    [style]="{ maxWidth: '800px', marginTop: '30px'  }"
    [(visible)]="modal"
    [closable]="true"
    [closeOnEscape]="true"
    [dismissableMask]="false"
    [modal]="true"
    [position]="'center'"
    [draggable]="false"
  >
    <ng-template pTemplate="header">
      <div class="w-100 text-center">
        <h4 class="bold text-primary">Choose a payment method</h4>
      </div>
    </ng-template>

    <section>
      <div class="row">
        <div class="col-12 bold">Currently Linked</div>
        <div class="col-6">
          <div class="border-style-payment w-100 p-3 cursor-pointer">
            <div class="row">
              <div class="col"><input type="radio" name="current" checked /></div>
              <div class="col">
                <div class="float-right">
                  <i class="fas fa-bars mr-1"></i>
                </div>
              </div>
            </div> 
            <div class="d-flex">
              <img
                src="assets/img/maya.png"
                alt="User Avatar"
                class="img-size-50 mr-3 img-circle"
              />
              <h4 class="bold mt-3">Maya ****-***-9999</h4>
            </div>
          </div>
        </div>
        <div class="col-12 bold mt-4">Add methods</div>
        <div class="col-6">
          <div class="border-style-method w-100 p-4 cursor-pointer" (click)="subscriptionAddBank?.onOpen()">
            <div class="d-flex">
              <i class="fas fa-landmark fa-3x mr-3 text-primary"></i>
              <h4 class="bold mt-2">Bank Account</h4>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="border-style-method w-100 p-4 cursor-pointer" (click)="subscriptionAddEwallet?.onOpen()">
            <div class="d-flex">
              <i class="fas fa-wallet fa-3x mr-3 text-primary"></i>
              <h4 class="bold mt-2">E-Wallet</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <p-footer>
      <div class="w-100 text-center">
        <button role="button" class="btn btn-primary btn-lg px-4 mr-4" >
            Pay Now
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg px-4" (click)="modal = false">
            Cancel
        </button>
      </div>
    </p-footer>
  </p-dialog>
</div>

<app-subscription-add-bank #subscriptionAddBank></app-subscription-add-bank>
<app-subscription-add-ewallet #subscriptionAddEwallet></app-subscription-add-ewallet>