import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER, IDLE_TIME_LIMIT } from 'src/app/utils/items/storage-names';
import { ApiService } from 'src/app/utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';
import { AudioService } from 'src/app/utils/services/audio.service';
import { CallService } from 'src/app/utils/services/azure/call.service';
import { PusherClientService } from 'src/app/utils/services/pusher/pusher-client.service';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { UtilitiesService } from 'src/app/utils/services/utilities/utilities.service';
import { VideoContainerService } from 'src/app/utils/services/video-container.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('contentWrapper', { static: false }) contentWrapper;

  public sidebarMenuOpened = true;
  isOnboarding: boolean = false;

  incomingCalls = [];
  initialized = false;
  pusher: any;
  user: any;

  subscriptions = [];

  isVideoContainerVisible: boolean = false;
  private subscription: Subscription;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private service: ApiService,
    private utilService: UtilitiesService,
    private pusherService: PusherClientService,
    private scheduleService: ScheduleService,
    private cookieService: CookieService,
    private callService: CallService,
    private router: Router,
    private audio: AudioService,
    private videoContainerService: VideoContainerService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    /* this.appService.userSubject.subscribe((response: any) => {
      this.user = response;
      this.checkOnboarding();
    }); */

    const loginTimeString = localStorage.getItem(IDLE_TIME_LIMIT);
    if (loginTimeString) {
      const loginTime = new Date(loginTimeString);
      const currentTime = new Date();
      const timeDiffInMs = loginTime.getTime() - currentTime.getTime();
      const timeDiffInHours = timeDiffInMs / (1000 * 60 * 60);

      if (timeDiffInHours <= 0) {
        localStorage.clear();
        localStorage.setItem('user-inactive', 'true'); 
        this.router.navigate(['/logout']);
      }
    }
    
    this.user = this.appService.getCurrentUser();
    this.init();
    this.getUtilities();
    this.checkOnboarding();
    // this.callService.initialize();

    this.pusherService.pusherSubject.subscribe((response: any) => {
      this.pusher = response;
      this.subscribeToChannel();
    });

    this.subscription = this.videoContainerService.isVisible$.subscribe(
      isVisible => {
        this.isVideoContainerVisible = isVisible;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkOnboarding() {
    this.sidebarMenuOpened = +this.user?.is_new_user !== 1 ? true : false;
    this.toggleMenuSidebar();
  }

  init() {
    if (this.cookieService.get('settings.dark-mode')) {
      this.document.body.classList.add('dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
    }
  }

  subscribeToChannel() {
    this.pusher.subscribe(`private-doctor-consultation-channel-${this.user?.id}`)
      ?.bind("waiting-room", (data: any) => {
        if (data?.is_auto_booking) {
          // this.audio.newAppointment();
          // this.incomingCalls.push(data);
        }
      });
    this.pusher.subscribe(`private-appointment-channel-${getStorage(CURRENT_USER, true)?.id}`)?.bind("patient-cancel-appointment", (data: any) => {
      const index = this.incomingCalls?.findIndex((appointment) => +appointment?.appointment_id === +data?.appointment_id);
      if (index > -1) {
        this.incomingCalls.splice(index, 1);
      }
    });
  }

  mainSidebarHeight(height) {
    // this.renderer.setStyle(
    //   this.contentWrapper.nativeElement,
    //   'min-height',
    //   height - 114 + 'px'
    // );
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
      this.renderer.addClass(document.body, 'sidebar-open');
      this.sidebarMenuOpened = true;
    }
  }
  getUtilities() {
    const includes: any[] = [
      '101', // Marital Status
      '103', // Nationality
      '104', // religion
      '158', // languages
      '106', // Contact Types
      '108', // Purpose of contact
      '107', // Relationship
      '158', // Languanges
      '110', // Identity types
    ];

    const queryParams: any = {};
    queryParams.perPage = 'all';
    queryParams.includes = 'details';
    queryParams.codes = includes.join(',');

    this.utilService.getValueMaster(queryParams)
      .subscribe((response: any) => {
        this.initialized = true;
        localStorage.setItem('value_master', JSON.stringify(response?.data));
      },
        (err) => { });
  }

  declineCall(data) {
    this.scheduleService.declineAppointment(data?.appointment_id, {
      decline_reason: data?.decline_reason
    })
      .subscribe((response: any) => {
        this.incomingCalls.splice(data?.index, 1);
      });
  }

  removeCall(index) {
    this.incomingCalls.splice(index, 1);
  }
}
