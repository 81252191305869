import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-add-bank',
  templateUrl: './subscription-add-bank.component.html',
  styleUrls: ['./subscription-add-bank.component.scss']
})
export class SubscriptionAddBankComponent implements OnInit {
  
  modal = false;

  constructor() {}

  ngOnInit(): void {
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}
