import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CasService } from 'src/app/utils/services/cas.service';
import { passwordValidator } from 'src/app/utils/validators/user-password.validator';

@Component({
  selector: 'app-sign-up-welcome',
  templateUrl: './sign-up-welcome.component.html',
  styleUrls: ['./sign-up-welcome.component.scss']
})
export class SignUpWelcomeComponent implements OnInit {
  form: any;
  processing = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  confirmPassword = false;
  emailPassword = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private casService: CasService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-container');
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8), passwordValidator()]],
      confirm_password: [null, [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
  }

  cancel() {
    this.router.navigate(['/logout']);
  }

  submit() {
    this.form.markAllAsTouched()
    if (this.form.invalid) {
      return
    }
    this.processing = true;
    const password = this.form.value.password;
    const confirm_password = this.form.value.confirm_password;

    if (password == confirm_password) {
      this.casService.register(this.form.value)
        .subscribe((response: any) => {
          const email = btoa(this.form.value.email)
          localStorage.setItem('user-registration', JSON.stringify({email}))
          this.processing = false;
          this.toastr.success("Successfully submitted!");
          this.router.navigate(['/sign-up-doctors/verify-code']);
        }, (err) => {
          this.processing = false;
          console.log('err ', err)
          this.toastr.error(err?.error?.message, '', {
            timeOut: 3000
          });
        })
    } else {
      this.processing = false;
      this.toastr.error("Password and Confirm Password should be same!", '', {
        timeOut: 3000
      });
    }
  }

  getControl(name) {
    return this.form.get(name);
  }

  triggerShowPassword(password){
    if (password === 'pass') {
      this.showPassword = !this.showPassword; 
    }
    if (password === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword; 
    }
  }

  getPasswordType(password){
    if (password === 'pass') {
      if (this.showPassword){
        return 'text';
      }
      else{
        return 'password';
      }
    }

    if (password === 'confirm') {
      if (this.showConfirmPassword){
        return 'text';
      }
      else{
        return 'password';
      }
    }
    
  }

  checkConfirmPassword() {
    this.confirmPassword = this.form.get('password').value === this.form.get('confirm_password').value;
    this.emailPassword = this.form.get('password').value === this.form.get('email').value;
  }

}
