import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-onboarding-modal',
  templateUrl: './welcome-onboarding-modal.component.html',
  styleUrls: ['./welcome-onboarding-modal.component.scss']
})
export class WelcomeOnboardingModalComponent implements OnInit {

  constructor(
    private router: Router,
    private _appService: AppService,
    private doctorService: DoctorService,
  ) { }

  modal = true;
  currentUser: any;
  teleconsultationProgress: any;

  ngOnInit(): void {
    this.currentUser = this._appService.getCurrentUser();
    this.getTeleconsultationProgress();
  }

  async getTeleconsultationProgress() {
    this.doctorService.getTeleconsultationProgress(this.currentUser?.id,{})
    .subscribe((response: any) => {
      this.teleconsultationProgress = response;
      console.log(this.teleconsultationProgress);
    });
  }

  onGetStart() {
    this.modal = false
    if (!this.teleconsultationProgress.profile_info){
      this.router.navigate(['/profile/information']);
    }
    else if (!this.teleconsultationProgress.credentials){
      this.router.navigate(['/profile/credentials']);
    }
    else{
      this.router.navigate(['/profile/financial']);
    }
  }

}
