import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-add-ewallet',
  templateUrl: './subscription-add-ewallet.component.html',
  styleUrls: ['./subscription-add-ewallet.component.scss']
})
export class SubscriptionAddEwalletComponent implements OnInit {

  modal = false;

  constructor() {}

  ngOnInit(): void {
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}
