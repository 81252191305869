<div>
  <div class="card light-shadow position-relative">
    <div class="grid">
      <div class="row">
        <div class="col-12 text-center">
          <div class="my-3">
            <img class="logo pr-2" src="assets/img/mwell-MD-icon.png" width="90px">
            <img class="logo" src="assets/img/logo.png" width="200px">
          </div>
          <div class="welcome-text">Welcome to the EMR Sign up page!</div>
          <span class="font-family-poppins">This portal is exclusively for doctors.</span>
          <div class="welcome-sub-text">
            <span>To get started, please complete the sign-up process and submit your PRC ID for validation.</span>
            <span>Once your credentials are verified, you'll be granted a free account.</span>
            <span class="mt-3">After a brief validation period, you'll gain access to the advanced features of the
              EMR.</span>
            <span>Thank you for patience and cooperation.</span>
          </div>
          <div class="sign-up-form">
            <form *ngIf="form" [formGroup]="form">
              <fieldset [disabled]="processing">
                <div class="form-group">
                  <label>Email address</label>
                  <b class="ml-2 text-danger" *ngIf="getControl('email')?.errors?.required">*</b>
                  <input formControlName="email" type="email" class="form-control" placeholder="Email" />
                  <small class="text-danger"
                    *ngIf="getControl('email').touched && getControl('email')?.errors?.required">Email Address is
                    required.</small>
                  <small class="text-danger"
                    *ngIf="getControl('email').touched && getControl('email')?.errors?.email">Please provide a valid
                    email address.</small>
                </div>
                <div class="form-group position-relative password-container">
                  <label for="passwordInput">Password <b class="ml-2 text-danger"
                      *ngIf="getControl('password')?.errors?.required">*</b></label>
                  <!-- <input id="passwordInput" [type]="getPasswordType('pass')" formControlName="password" class="form-control" placeholder="Password" (keyup)="checkConfirmPassword()"> -->
                  <p-password formControlName="password" [feedback]="false" [toggleMask]="true" placeholder="Password"
                    (keyup)="checkConfirmPassword()"></p-password>
                  <small class="text-danger"
                    *ngIf="getControl('password').touched && getControl('password')?.errors?.required">Password is
                    required. <br></small>
                  <small class="text-danger"
                    *ngIf="getControl('password').touched && getControl('password')?.errors?.minlength">Password must be
                    at least 8 characters long. <br></small>
                  <small class="text-danger" *ngIf="emailPassword">Password should be different from email. <br></small>
                  <small class="text-danger"
                    *ngIf="getControl('password').touched && getControl('password')?.errors?.passwordRequirements">Password
                    must contain characters from at least 3 out of the 4 categories:
                    uppercase letter, lowercase letter, number, and special character.</small>
                  <!-- <i (click)="triggerShowPassword('pass')" *ngIf="!showPassword" class="fa fa-eye password-eye"></i>
                  <i (click)="triggerShowPassword('pass')" *ngIf="showPassword" class="fa fa-eye-slash password-eye"></i>                   -->
                </div>
                <div class="form-group position-relative password-container">
                  <label for="passwordInput">Confirm Password <b class="ml-2 text-danger"
                      *ngIf="getControl('confirm_password')?.errors?.required">*</b></label>
                  <!-- <input id="passwordInput" [type]="getPasswordType('confirm')" formControlName="confirm_password"
                    class="form-control" placeholder="Confirm Password" (keyup)="checkConfirmPassword()"> -->
                  <p-password formControlName="confirm_password" [feedback]="false" [toggleMask]="true"
                    placeholder="Confirm Password" (keyup)="checkConfirmPassword()"></p-password>
                  <small class="text-danger"
                    *ngIf="getControl('confirm_password').touched && getControl('confirm_password')?.errors?.required">Confirm
                    Password is required. <br></small>
                  <small class="text-danger"
                    *ngIf="getControl('confirm_password').touched && getControl('confirm_password')?.errors?.minlength">Confirm
                    Password must be at least 8 characters long. <br></small>
                  <small class="text-danger" *ngIf="getControl('confirm_password').touched && !confirmPassword">Password
                    and Confirm Password must match.</small>
                  <!-- <i (click)="triggerShowPassword('confirm')" *ngIf="!showConfirmPassword"
                    class="fa fa-eye password-eye"></i>
                  <i (click)="triggerShowPassword('confirm')" *ngIf="showConfirmPassword"
                    class="fa fa-eye-slash password-eye"></i> -->
                </div>

                <div class="row d-flex pt-3">
                  <div class="col-6">
                    <a href="javascript:;" class="btn btn-outline-primary btn-block" (click)="cancel()">Cancel</a>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary btn-block"
                      [disabled]="form?.invalid || !confirmPassword" (click)="submit()">
                      Submit
                      <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <!-- <div class="sign-up-with">
            <span>Or sign up with</span>
          </div>
          <div>
            <img src="./assets/img/apple_icon.png" class="apps-platform-icons ml-4 cursor-pointer">
            <img src="./assets/img/google_icon.png" class="apps-platform-icons ml-4 cursor-pointer">
            <img src="./assets/img/fb_icon.png" class="apps-platform-icons ml-4 cursor-pointer">
            <img src="./assets/img/whatsapp_icon.png" class="apps-platform-icons ml-4 cursor-pointer">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>