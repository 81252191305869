<div>
  <p-dialog [baseZIndex]="2100" [style]="{ minWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
  [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <ng-template pTemplate="header">
      <h3 class="text-primary bold-700 text-uppercase">REQUEST LEAVE FORM</h3>
    </ng-template>
    
    <form [formGroup]="form" *ngIf="form">
      <fieldset>
        <div class="d-flex mb-4 pt-2 filter-inputs">
            <div class="w-100">
                <span for="leave_type" class="text-primary bold">Type of Leave</span>
                <b class="ml-2 text-danger">*</b>
                <select [(ngModel)]="form.leave_type" class="form-control w-100" name="leave_type" id="leave_type" (change)="list()"
                    formControlName="leave_type">
                    <option value="Maternity Leave">Maternity Leave</option>
                    <option value="Sick Leave">Sick Leave</option>
                    <option value="Bereavement Leave">Bereavement Leave</option>
                    <option value="Vacation Leave">Vacation Leave</option>
                    <option value="Paternity Leave">Paternity Leave</option>
                    <option value="Emergency Leave">Emergency Leave</option>
                </select>
            </div>
        </div>
        <div class="d-flex mb-4 pt-2 filter-inputs">
            <div class="pr-2">
                <span for="start_date" class="text-primary bold">Date From</span>
                <b class="ml-2 text-danger">*</b>
                <input [(ngModel)]="form.start_date" type="date" class="form-control w-100" id="start_date" formControlName="start_date">
            </div>
            <div class="pl-2 pr-2">
                <span for="end_date" class="text-primary bold">Date To</span>
                <b class="ml-2 text-danger">*</b>
                <input [(ngModel)]="form.end_date" type="date" class="form-control w-100" id="end_date" formControlName="end_date">
            </div>
            <div class="pl-2 w-50">
                <span for="duration" class="text-primary bold">Type of Leave</span>
                <b class="ml-2 text-danger">*</b>
                <select [(ngModel)]="form.duration" class="form-control w-100" name="duration" id="duration" (change)="list()"
                    formControlName="duration">
                    <option value="Whole Day">Whole Day</option>
                    <option value="Half Day">Half Day</option>
                </select>
            </div>
        </div>
        <div class="d-flex mb-4 pt-2 filter-inputs">
          <input [multiple]="false" type="file" formControlName="file_upload" (change)="selectFile($event)" #fileUpload hidden/>
          <div class="upload-container-left pr-2">
            <div class="dropzone">
              <span class="title">Attachment/s</span>
              <div draggable="true" class="dropzone-area">
                <div class="label">Drop your file here</div>
                <div class="divider">-or-</div>
                <button (click)="fileUpload.click()" class="btn btn-primary">Browse</button>
              </div>
            </div>
          </div>
          <div class="upload-container-right pl-2">
              <ng-container *ngIf="fileName">
                <div class="uploaded-label">Uploaded Files</div>
                <div class="d-flex upload-container-content">
                  <div class="w-auto">
                    <ng-container *ngIf="checkIfFileType('pdf')">
                      <i class="fas fa-file-pdf file-icon"></i>
                    </ng-container>
                    <ng-container *ngIf="checkIfFileType('image')">
                      <i class="fas fa-image file-icon"></i>
                    </ng-container>
                    <ng-container *ngIf="checkIfFileType('.doc') || checkIfFileType('.docx')">
                      <i class="fas fa-file-word file-icon"></i>
                    </ng-container>
                  </div>
                  <div class="w-75 pl-4">
                    <div>
                      <span class="upload-filename">{{ fileName }}</span>
                      {{ fileSize }}mb
                    </div>
                    <div class="upload-percentage">100% completed</div>
                  </div>
                  <div class="w-auto">
                    <i class="fas fa-check-circle file-status-icon"></i>
                    <i (click)="removeUploadedFile()" class="fas fa-times-circle file-delete-icon"></i>
                  </div>
                </div>
              </ng-container>
          </div>
      </div>
        <div class="d-flex mb-4 pt-2 filter-inputs">
            <div class="w-100">
                <span for="status" class="text-primary bold">Reason</span>
                <textarea [(ngModel)]="form.reason" class="w-100" rows="5" class="form-control" formControlName="reason"
                    [disabled]="processing"></textarea>
            </div>
        </div>
      </fieldset>
    </form>

    <ng-template pTemplate="footer">
      <button role="button" class="btn btn-lg btn-primary" (click)="saveNewLeaveRequest()" [disabled]="processing">
        Submit
      </button>
      <button role="button" class="btn btn-lg btn-outline-primary" (click)="onClose()" [disabled]="processing">
        Cancel
      </button>
    </ng-template>
    <button style="display:none;" #notificationStatusModalButton (click)="notificationStatusModal?.onOpen()">Show Success Modal</button>
  </p-dialog>
</div>
<app-notification-status-modal status="success" title="Request Leave Sent" message="You have submitted your request successfully. Please check your email/notification. " #notificationStatusModal></app-notification-status-modal>