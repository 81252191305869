import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-plan-modal',
  templateUrl: './subscription-plan-modal.component.html',
  styleUrls: ['./subscription-plan-modal.component.scss']
})
export class SubscriptionPlanModalComponent implements OnInit {

  modal = false;

  constructor() {}

  ngOnInit(): void {
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}
