<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- <a [routerLink]="['/dashboard']" class="brand-link d-flex justify-content-center"> -->
  <!-- <span class="brand-text font-weight-light bold">Mwell MD</span> -->
  <!-- </a> -->
  <div class="sidebar mt-0 os-scrollbar">
    <div class="user-panel mt-3 pb-2 mb-1 d-flex">
      <div class="image">
        <img class="d-block m-auto sidebar-collapsed" src="assets/img/mwell-MD-icon.png" alt="User Image" />
        <img class="d-block m-auto sidebar-hovered" src="assets/img/logo.png" alt="User Image" />
      </div>
      <!-- <div class="info">
        <a [routerLink]="['/profile']" class="d-block">Alexander Pierce</a>
      </div> -->
    </div>
    <div class="row switch">
       <div class="col text-primary btn btn-light" (click)="switchToMwellBasic()">
        <span class="row"><i class="ticon icon-tc"></i> <span>Teleconsult</span></span>
      </div> 
      <div class="col p-3 btn-primary">
        <span class="row icon-emr-container"><i class="ticon icon-emr"></i> <span>EMR</span></span>
      </div>
    </div>
    <nav class="mt-2">
      <ng-container *ngIf="
          subscription?.subscription_status_code === 'active' || !subscription?.subscription_status_code;
          then active;
          else inactive
        ">
      </ng-container>
      <ng-template #active>
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item mt-2">
            <a [routerLink]="['/home']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('home')">
              <i class="nav-icon fa fa-home mr-2"></i>
              <p style="white-space: nowrap">Home</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/consults']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('consults')">
              <i class="nav-icon fas fa-list-alt mr-2"></i>
              <p style="white-space: nowrap">Outpatients</p>
            </a>
          </li>
          <!-- <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/teleconsultation']" class="nav-link cursor-pointer"
              [class.active]="isCurrentPath('teleconsultation')">
              <img src="assets/icons/teleconsultation_icon.png" class="nav-icon" alt="Teleconsultation" />
              <p style="white-space: nowrap">Teleconsultation</p>
            </a>
          </li> -->
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/in-patients']" class="nav-link cursor-pointer"
              [class.active]="isCurrentPath('in-patients')">
              <i class="nav-icon fas fa-h-square mr-2"></i>
              <p style="white-space: nowrap">Inpatients</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/patients']" class="nav-link cursor-pointer" [class.active]="
                isCurrentPath('patients') || isCurrentPath('patient')
              ">
              <i class="nav-icon fas fa-users mr-2"></i>
              <p style="white-space: nowrap">
                {{ _appService.currentUser?.role_name === "doctor" ? "My" : "" }}
                Patients
              </p>
            </a>
          </li>

          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/referrals']" class="nav-link cursor-pointer"
              [class.active]="isCurrentPath('referrals')">
              <i class="nav-icon fas fa-share mr-2"></i>
              <p style="white-space: nowrap">
                {{ _appService.currentUser?.role_name === "doctor" ? "My" : "" }}
                Referrals
              </p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/medical-billing']" class="nav-link cursor-pointer"
              [class.active]="isCurrentPath('medical-billing')">
              <i class="nav-icon fas fa-calculator mr-2"></i>
              <p style="white-space: nowrap">Patient Fee Tracking</p>
            </a>
          </li>
          <li class="nav-item mt-2 hidden" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link cursor-pointer">
              <i class="nav-icon fas fa-id-badge mr-2"></i>
              <p style="white-space: nowrap">Professional Fees</p>
            </a>
          </li>
          <li class="nav-item mt-2 hidden" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link cursor-pointer">
              <i class="nav-icon fas fa-envelope mr-2"></i>
              <p style="white-space: nowrap">Inbox/Messages</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a [routerLink]="['/reports']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('reports')">
              <i class="nav-icon fas fa-chart-bar mr-2"></i>
              <p style="white-space: nowrap">Reports</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <!--          <a [routerLink]="['/profile']" class="nav-link cursor-pointer"-->
            <!--            [class.active]="isCurrentPath('profile') && (isCurrentPath('information') || isCurrentPath('credentials') || isCurrentPath('security'))"-->
            <!--          >-->
            <a [routerLink]="['/profile']" class="nav-link cursor-pointer" [class.active]="isCurrentPath('profile')">
              <i class="nav-icon fas fa-user-md mr-2"></i>
              <p style="white-space: nowrap">
                {{
                _appService.currentUser?.role_name === "doctor" ? "My" : "Doctor's"
                }}
                Profile
              </p>
            </a>
          </li>
          <ng-template [appHasRole]="['doctor']">
            <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
              <a [routerLink]="['/staff-accounts']" class="nav-link cursor-pointer"
                [class.active]="isCurrentPath('staff-accounts')">
                <i class="nav-icon fas fa-users mr-2"></i>
                <p style="white-space: nowrap">Staff Accounts</p>
              </a>
            </li>
          </ng-template>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link cursor-pointer" data-toggle="collapse" data-target="#settings-list" aria-expanded="false"
              aria-controls="settings-list" [class.active]="isCurrentPath('settings')">
              <i class="nav-icon fas fa-cog mr-2"></i>
              <p style="white-space: nowrap">Settings</p>
            </a>
            <div class="collapse" id="settings-list">
              <ul style="list-style-type: none" class="pl-0">
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/profile']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>
                      {{
                      _appService.currentUser?.role_name === "doctor"
                      ? "My"
                      : "Doctor's"
                      }}
                      Profile
                    </p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/user-accounts']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>User Accounts</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/clinics-schedules']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Clinics and Schedules</p>
                  </a>
                </li>
                <li class="text-muted" *appHasRole="['doctor', 'staff']">
                  <a [routerLink]="['/settings/billable-items']" class="nav-link cursor-pointer pl-4"
                    [class.active]="isCurrentPath('billable-items')">
                    <i class="nav-icon fas fa-receipt"></i>
                    <p>Billable Items</p>
                  </a>
                </li>
                <li class="text-muted" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/merge-patients']" class="nav-link cursor-pointer pl-4"
                    [class.active]="isCurrentPath('merge-patients')">
                    <i class="nav-icon fas fa-exchange-alt"></i>
                    <p>Merge Patients</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/templates-library']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Templates Library</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/custom-forms']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Custom Forms</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/preferences']" class="nav-link cursor-pointer">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Preferences</p>
                  </a>
                </li>
                <li class="text-muted" *appHasRole="['doctor']">
                  <a [routerLink]="['/settings/custom-templates']" class="nav-link cursor-pointer pl-4"
                    [class.active]="isCurrentPath('custom-templates')">
                    <i class="nav-icon fas fa-th-large"></i>
                    <p>Custom Templates</p>
                  </a>
                </li>
                <!-- <ng-container *ngIf="subscription?.payment_status_code !== 'free'">
                  <li class="text-muted" *appHasRole="['doctor']">
                    <a [routerLink]="['/settings/subscription']" class="nav-link cursor-pointer pl-4"
                      [class.active]="isCurrentPath('subscription')">
                      <i class="nav-icon fas fa-certificate"></i>
                      <p>Subscription</p>
                    </a>
                  </li>
                </ng-container> -->

              </ul>
            </div>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link cursor-pointer" (click)="logout()" [attr.disabled]="processing">
              <i *ngIf="!processing" class="nav-icon fas fa-sign-out-alt mr-2"></i>
              <i *ngIf="processing" class="nav-icon fas fa-circle-notch spinning"></i>
              <p style="white-space: nowrap">Log out</p>
            </a>
          </li>
        </ul>
      </ng-template>

      <ng-template #inactive>
        <ul class="nav nav-pills nav-sidebar flex-column inactive hidden" data-widget="treeview" role="menu"
          data-accordion="false">
          <li class="nav-item mt-2">
            <a class="nav-link">
              <i class="nav-icon fa fa-home mr-2"></i>
              <p style="white-space: nowrap">Home</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-list-alt mr-2"></i>
              <p style="white-space: nowrap">Outpatients</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-h-square mr-2"></i>
              <p style="white-space: nowrap">Inpatients</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-users mr-2"></i>
              <p style="white-space: nowrap">
                {{ _appService.currentUser?.role_name === "doctor" ? "My" : "" }}
                Patients
              </p>
            </a>
          </li>

          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-share mr-2"></i>
              <p style="white-space: nowrap">
                {{ _appService.currentUser?.role_name === "doctor" ? "My" : "" }}
                Referrals
              </p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-calculator mr-2"></i>
              <p style="white-space: nowrap">Patient Fee Tracking</p>
            </a>
          </li>
          <li class="nav-item mt-2 hidden" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-id-badge mr-2"></i>
              <p style="white-space: nowrap">Professional Fees</p>
            </a>
          </li>
          <li class="nav-item mt-2 hidden" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-envelope mr-2"></i>
              <p style="white-space: nowrap">Inbox/Messages</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link">
              <i class="nav-icon fas fa-chart-bar mr-2"></i>
              <p style="white-space: nowrap">Reports</p>
            </a>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <!--          <a [routerLink]="['/profile']" class="nav-link "-->
            <!--            [class.active]="isCurrentPath('profile') && (isCurrentPath('information') || isCurrentPath('credentials') || isCurrentPath('security'))"-->
            <!--          >-->
            <a class="nav-link">
              <i class="nav-icon fas fa-user-md mr-2"></i>
              <p style="white-space: nowrap">
                {{
                _appService.currentUser?.role_name === "doctor" ? "My" : "Doctor's"
                }}
                Profile
              </p>
            </a>
          </li>
          <ng-template [appHasRole]="['doctor']">
            <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
              <a class="nav-link">
                <i class="nav-icon fas fa-users mr-2"></i>
                <p style="white-space: nowrap">Staff Accounts</p>
              </a>
            </li>
          </ng-template>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link" data-toggle="collapse" data-target="#settings-list" aria-expanded="false"
              aria-controls="settings-list">
              <i class="nav-icon fas fa-cog mr-2"></i>
              <p style="white-space: nowrap">Settings</p>
            </a>
            <div class="collapse" id="settings-list">
              <ul style="list-style-type: none" class="pl-0">
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>
                      {{
                      _appService.currentUser?.role_name === "doctor"
                      ? "My"
                      : "Doctor's"
                      }}
                      Profile
                    </p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>User Accounts</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Clinics and Schedules</p>
                  </a>
                </li>
                <li class="text-muted" *appHasRole="['doctor', 'staff']">
                  <a class="nav-link pl-4">
                    <i class="nav-icon fas fa-receipt"></i>
                    <p>Billable Items</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Templates Library</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Custom Forms</p>
                  </a>
                </li>
                <li class="text-muted hidden" *appHasRole="['doctor']">
                  <a class="nav-link">
                    <i class="nav-icon fas fa-circle"></i>
                    <p>Preferences</p>
                  </a>
                </li>
                <li class="text-muted" *appHasRole="['doctor']">
                  <a class="nav-link pl-4">
                    <i class="nav-icon fas fa-th-large"></i>
                    <p>Custom Templates</p>
                  </a>
                </li>
                <!-- <li class="text-muted" *appHasRole="['doctor']">
                  <a class="nav-link pl-4">
                    <i class="nav-icon fas fa-certificate"></i>
                    <p>Subscription</p>
                  </a>
                </li> -->
              </ul>
            </div>
          </li>
          <li class="nav-item mt-2" *ngIf="user?.doctor?.prc_license?.is_verified">
            <a class="nav-link cursor-pointer" (click)="logout()" [attr.disabled]="processing"
              style="color: #808080 !important">
              <i *ngIf="!processing" class="nav-icon fas fa-sign-out-alt mr-2"></i>
              <i *ngIf="processing" class="nav-icon fas fa-circle-notch spinning"></i>
              <p style="white-space: nowrap">Log out</p>
            </a>
          </li>
        </ul>
      </ng-template>
    </nav>
  </div>
</aside>